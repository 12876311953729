export const AboutInfo = () => {
  return (
    <div className="about-info">
      ryan ardito - <a href="mailto:ryan.m.ardito@gmail.com">email</a> -{" "}
      <a href="https://github.com/Ryan-Ardito/fractile" target="_blank">
        code
      </a>
    </div>
  );
};
